<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit a Golfer</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="edit-golfer-form">
          <v-radio-group v-model="fields.linkType">
            <template v-slot:label>
              <div>What is their link to this Tour?</div>
            </template>
            <v-radio label="Lead" value="lead"></v-radio>
            <v-radio label="Admin" value="admin"></v-radio>
            <v-radio label="Standard" value="standard"></v-radio>
          </v-radio-group>
          <v-radio-group v-model="fields.golferType">
            <template v-slot:label>
              <div>Are they a Golfer or a Non-Golfer?</div>
            </template>
            <v-radio label="Golfer" value="golfer"></v-radio>
            <v-radio label="Non-Golfer" value="non-golfer"></v-radio>
          </v-radio-group>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="edit-golfer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    itinerary: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      golfer: {},
      fields: {
        linkType: null,
        golferType: null,
      },
      errors: {},
    };
  },

  methods: {
    openForm: function (golfer) {
      this.golfer = golfer;
      this.fields.linkType = golfer.pivot.link_type.toLowerCase();
      this.fields.golferType = golfer.pivot.is_golfer ? "golfer" : "non-golfer";
      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.golfer = {};
      this.fields.linkType = null;
      this.fields.golferType = null;
      this.errors = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const golferId = this.golfer.id;
      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        tourId,
        golferId,
        isEditing: true,
        fields: this.fields,
      };

      let action = "drum/tours/saveGolfer";

      if (this.itinerary) {
        payload.itineraryId = this.itinerary.id;
        action = "drum/tours/saveGolferItinerary";
      }

      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>