<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title>
        <div class="headline">Add a Golfer</div>
        <v-spacer></v-spacer>
        <v-btn color="accent" depressed @click="createNewGolfer()"
          >Create New Golfer</v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="add-golfer-form">
          <v-autocomplete
            label="Golfer"
            v-model="customer_id"
            :items="golfers"
            item-text="full_name"
            item-value="id"
            outlined
            hide-details
            background-color="white"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="add-golfer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    itinerary: {
      type: Object,
      required: true,
    },
    tour: {
      type: Object,
      required: true,
    },
    openCreateGolferForm: Function,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      customer_id: null,
    };
  },

  computed: {
    golfers() {
      return this.tour.customers.filter((g) => {
        let match = false;
        this.itinerary.customers.forEach((y) => {
          if (y.id === g.id) match = true;
        });
        return !match;
      });
    },
  },

  methods: {
    openForm: function () {
      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.customer_id = null;
    },

    saveForm: function () {
      this.loading = true;
      const golfer = this.golfers.find((g) => g.id === this.customer_id);

      this.$store
        .dispatch("drum/tours/saveGolferItinerary", {
          appId: this.$route.params.id,
          tourId: this.$route.params.tourId,
          itineraryId: this.$route.params.itineraryId,
          isEditing: false,
          fields: {
            customer_id: this.customer_id,
            link_type: golfer.pivot.link_type.toLowerCase(),
            is_golfer: golfer.pivot.is_golfer,
          },
        })
        .then(() => {
          this.resetForm();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createNewGolfer: function () {
      this.resetForm();
      this.openCreateGolferForm();
    },
  },
};
</script>