<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Create a new Golfer</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-stepper v-model="stepper">
          <v-stepper-header>
            <v-stepper-step :complete="stepper > 1" step="1">
              Golfer's Name
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="stepper > 2" step="2">
              Link To Customer
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="stepper > 3" step="3">
              Golfer's Tour Details
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="mb-6">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="First Name"
                      v-model="fields.firstName"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Last Name"
                      v-model="fields.lastName"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-btn color="accent" @click="stepTwo">Next</v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card outlined class="mb-6" v-if="!fields.createNewCustomer">
                <v-card-title>
                  We have found some potential customers that we think match
                  {{ fields.firstName }}
                </v-card-title>
                <v-card-text>
                  If any of these customers match your golfer, please select the
                  customer below. If not, press the button underneath to create
                  a new Customer
                </v-card-text>
                <v-card-text>
                  <v-select
                    label="Matching Customers"
                    v-model="fields.customer_id"
                    :items="potentialCustomers"
                    item-value="id"
                    :item-text="
                      (item) => item.full_name + ' (' + item.email + ')'
                    "
                    outlined
                    hide-details
                  ></v-select>
                </v-card-text>
                <v-card-actions>
                  <v-btn depressed @click="fields.createNewCustomer = true"
                    >Create a new customer</v-btn
                  >
                </v-card-actions>
              </v-card>
              <v-card outlined class="mb-6" v-else>
                <v-card-title>Create a new Customer for Golfer</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        label="First Name"
                        v-model="fields.firstName"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        label="Last Name"
                        v-model="fields.lastName"
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        label="Email"
                        v-model="fields.email"
                        type="email"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        label="Phone"
                        v-model="fields.phone"
                        type="phone"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    label="Address Line 1"
                    v-model="fields.address1_1"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    label="Address Line 2"
                    v-model="fields.address1_2"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    label="Address Line 3"
                    v-model="fields.address1_3"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    label="City"
                    v-model="fields.address1_city"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    label="County"
                    v-model="fields.address1_county"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    label="Postcode"
                    v-model="fields.address1_postcode"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    label="Country"
                    v-model="fields.address1_country"
                    outlined
                  ></v-text-field>
                </v-card-text>
              </v-card>
              <div>
                <v-btn color="accent" @click="stepper = 3">Next</v-btn>
                <v-btn text @click="stepper = 1">Back</v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card outlined class="mb-6">
                <v-card-text>
                  <v-alert
                    type="error"
                    icon="mdi-alert-circle"
                    v-if="errorMessage"
                    >{{ errorMessage }}</v-alert
                  >
                  <v-radio-group v-model="fields.linkType">
                    <template v-slot:label>
                      <div>What is their link to this Tour?</div>
                    </template>
                    <v-radio label="Lead" value="Lead"></v-radio>
                    <v-radio label="Admin" value="Admin"></v-radio>
                    <v-radio label="Standard" value="Standard"></v-radio>
                  </v-radio-group>
                  <v-radio-group v-model="fields.golferType">
                    <template v-slot:label>
                      <div>Are they a Golfer or a Non-Golfer?</div>
                    </template>
                    <v-radio label="Golfer" value="golfer"></v-radio>
                    <v-radio label="Non-Golfer" value="non-golfer"></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
              <div>
                <v-btn
                  color="accent"
                  type="submit"
                  :loading="loading"
                  :disabled="stepper < 3"
                  @click="saveForm"
                  >Save</v-btn
                >
                <v-btn text @click="stepper = 2">Back</v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    itinerary: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      stepper: 1,
      stepTwoComplete: false,
      potentialCustomers: [],
      fields: {
        firstName: "",
        lastName: "",
        createNewCustomer: false,
        customer_id: null,
        email: "",
        phone: "",
        address1_1: "",
        address1_2: "",
        address1_3: "",
        address1_city: "",
        address1_county: "",
        address1_postcode: "",
        address1_country: "",
        linkType: "Standard",
        golferType: "golfer",
      },
      errors: {},
      errorMessage: null,
    };
  },

  methods: {
    openForm: function () {
      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.stepper = 1;
      this.stepTwoComplete = false;
      this.potentialCustomers = [];
      this.fields.createNewCustomer = false;
      this.fields.firstName = "";
      this.fields.lastName = "";
      this.fields.linkType = "Standard";
      this.fields.golferType = "golfer";
      this.errors = {};
      this.errorMessage = null;
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      this.loading = true;
      this.errors = {};
      this.errorMessage = null;

      let fields = this.fields;

      if (this.itinerary) {
        fields.itinerary_id = this.itinerary.id;
      }

      this.$store
        .dispatch("drum/tours/saveGolfer", {
          appId,
          tourId,
          isEditing: false,
          fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          if (err.response.data.hasOwnProperty("message")) {
            this.errorMessage = err.response.data.message;
          } else {
            this.errors = err.response.data.errors;
          }

          this.loading = false;
        });
    },

    stepTwo: function () {
      if (this.stepTwoComplete === false) {
        const potentialCustomers = this.$store.getters[
          "drum/customers/getMatchingCustomers"
        ](this.fields.firstName, this.fields.lastName);

        if (potentialCustomers.length > 0) {
          this.potentialCustomers = potentialCustomers;
        } else {
          this.fields.createNewCustomer = true;
        }
      }

      this.stepper = 2;
    },
  },
};
</script>